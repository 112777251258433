import {
  API_CALL_WIZARD_API_REQUEST,
  API_CALL_WIZARD_API_SUCCESS,
  API_CALL_WIZARD_API_ERROR,
  SEARCH_CALL_WIZARD_API_REQUEST,
  SEARCH_CALL_WIZARD_API_SUCCESS,
  SEARCH_CALL_WIZARD_API_ERROR,
  ADD_INITIAL_WIZARD_DATA_SUCCESS,
  EMPTY_INITIAL_WIZARD_DATA_SUCCESS,
  ADD_LAST_ENTRY_POINT_SUCCESS,
  RESET_WIZARD_DATA_SUCCESS,
  RESET_WIZARD_SUCCESS,
  RESET_WIZARD_FIELDS_ONCHANGE,
  GET_WIZARD_API_REQUEST,
  GET_WIZARD_API_SUCCESS,
  GET_WIZARD_API_ERROR,
  GET_MODAL_WIZARD_API_REQUEST,
  GET_MODAL_WIZARD_API_SUCCESS,
  GET_MODAL_WIZARD_API_ERROR,
  LOADING_WIZARD_SUCCESS,
  ADD_LOCAL_WIZARD_SUCCESS,
  ADD_LOCAL_MODAL_WIZARD_SUCCESS,
  RESET_WIZARD_FLAG_SUCCESS,
  SET_API_CALL_PENDING,
  SET_MODAL_API_CALL_PENDING,
  ADD_MODAL_WIZARD_SLUG_SUCCESS,
  RESET_MODAL_WIZARD_SLUG_SUCCESS,
  RESET_APICALL_WIZARD_RESPONSE,
  SET_CHECKBOX_STATE,
  DUTCH_API_CALL_WIZARD_API_SUCCESS,
  DUTCH_API_CALL_WIZARD_API_ERROR,
} from '../../constants/wizardActionNames';
import {
  SELECT_CLIENTS_SUCCESS,
  SIGNIN_API_SUCCESS,
} from '../../constants/authActionNames';

// import lobTypes from '../../utils/variables/lobTypes.json';
import { statusOk, persistKey } from '../../utils/variables';
import {
  checkComponent,
  transformReducerValue,
} from '../../componentsV2/wizards/functions';
import {
  createAccountsPayload,
  updateHoldingsWithCalculations,
} from '../portfolio/investments';
import { createPolicies, createCustomPolicies } from '../portfolio/insurance';
import {
  mergeArraysDeep,
  addItemOnce,
  mergeDeep,
  getRef,
  setRef,
  sourceTextFromData,
  transformConditionalValue,
  dateToString,
  stringToDate,
  dateToReadableString,
  isCurrentMonth,
} from '../../utils/functions';

import { createPartialClaim } from '../../utils/functions/backend-to-frontend-mappers/claims';
import { createPrecerts } from '../../utils/functions/backend-to-frontend-mappers/claims/precerts';
import { createPartialPayanything } from '../../utils/functions/backend-to-frontend-mappers/claims/createMakePayment';
import { createApplication } from '../../utils/functions/backend-to-frontend-mappers/applications';

export const initialState = {
  apiCallWizardResponse: {},
  isModalApiCallWizardPending: false,
  isApiCallWizardPending: false,
  isApiCallWizardError: false,
  isApiCallWizardSuccess: false,
  apiCallWizardError: null,
  allWizardData: {},
  allInitialWizardData: {},
  initialWizardData: {},
  isLoadingWizard: false,
  getWizardResponse: null,
  isGettingWizardPending: false,
  isGettingWizardError: false,
  isGettingWizardSuccess: false,
  getWizardError: null,
  wizard: null,
  getModalWizardResponse: null,
  isGettingModalWizardPending: false,
  isGettingModalWizardError: false,
  isGettingModalWizardSuccess: false,
  getModalWizardError: null,
  modalWizard: null,
  modalWizardSlug: null,
  searchCallWizardResponse: null,
  isSearchCallWizardPending: false,
  isSearchCallWizardError: false,
  isSearchCallWizardSuccess: false,
  searchCallWizardError: null,
  focusKey: '',
  lastEntryPoint: null,
  resettedWizardData: false,
  resetOnChange: false,
  nextBackButtonLink: null,
  lastAllInitialWizardData: null,
  previousWizardData: {},
};

const formatAccountsPayload = (accounts = [], filter) => {
  return accounts.map(x => {
    // const investmentLobObj = lobTypes.find(x2 => x2.lob === investmentLob);
    // const subType = investmentLobObj?.subTypes.find(x2 => x2.slug === x?.type); subType?.title
    const holdings = x?.holdings?.map(holding => {
      // const newSecurity = holding?.name; // `${holding?.security} - ${holding?.secCode}`;
      return {
        ...holding,
        // security: newSecurity,
        key: 'personal',
        // secCode: holding?.securityCode,
      };
    });

    if (filter) {
      const filteredHoldings = holdings.filter(
        y => y.quantity !== undefined && y.quantity !== null && y.quantity > 0,
      );

      return { ...x, holdings: filteredHoldings };
    }

    return { ...x, holdings };
  });
};

const filterResponsePayload = (payloadArray, filterSetting) => {
  if (!filterSetting) return payloadArray;
  const newPayload = payloadArray.filter(x => {
    const filterKey = filterSetting?.key;
    return filterSetting?.value?.toString() === x[filterKey]?.toString();
  });
  return newPayload;
};

function wizardReducer(state = initialState, action) {
  switch (action.type) {
    case RESET_APICALL_WIZARD_RESPONSE: {
      const { apiCallWizardResponse } = state;
      const { vehicleModels, manualCatYearInfo, ...rest } = apiCallWizardResponse;
      return {
        ...state,
        apiCallWizardResponse: rest,
      };
    }
    case API_CALL_WIZARD_API_REQUEST: {
      const savedKey = action?.savedKey;

      const initialPendingStatuses = state?.isApiCallWizardPending
        ? state?.isApiCallWizardPending
        : {};
      const initialErrorStatuses = state?.isApiCallWizardError
        ? state?.isApiCallWizardError
        : {};
      const initialSuccessStatuses = state?.isApiCallWizardSuccess
        ? state?.isApiCallWizardSuccess
        : {};

      const newPendingStatuses = {
        ...initialPendingStatuses,
        [savedKey]: true,
      };
      const newSuccessStatuses = { ...initialSuccessStatuses, [savedKey]: false };
      const newErrorStatuses = { ...initialErrorStatuses, [savedKey]: false };
      return {
        ...state,
        isApiCallWizardPending: newPendingStatuses,
        isApiCallWizardError: newErrorStatuses,
        isApiCallWizardSuccess: newSuccessStatuses,
      };
    }
    /*
    case SIGNIN_API_SUCCESS: {
      const user = { ...action?.payload?.payload } || null; // ...state?.user,
      // user.idVerification.canVerify = false;
      if (user.idVerification) {
        if (
          user?.idVerification?.canVerify === false ||
          user?.idVerification?.retryAttemptsExceeded === true
        ) {
          user.idVerification.continueApp = 'false';
        } else {
          user.idVerification.continueApp = 'true';
        }
      }
      const allInitialWizardData = state?.allInitialWizardData;
      allInitialWizardData.onFidoStatus = {
        payload: { idVerification: user?.idVerification },
      };
      const newState = { ...state, allInitialWizardData };
      return newState;
    }
    */

    case API_CALL_WIZARD_API_SUCCESS: {
      let response = action?.payload;
      const savedKey = action?.savedKey;
      const userId = state?.allInitialWizardData?.auth?.userId || 'none';
      let allInitialWizardData = { ...state?.allInitialWizardData };
      const filterSettings = action?.apiCall?.filterSettings;
      const params = action?.params;
      const oldStoreData = action?.oldStoreData;
      const httpMethod = action?.apiCall?.httpMethod;
      const lobTypes = action?.lobTypes;
      const appendKeys = action?.apiCall?.allInitialWizardDataAppendKeys;
      // console.log('action?.apiCall: ', action?.apiCall);
      const verifiedEmailField = action?.apiCall?.verifiedEmailField;

      if (
        httpMethod === 'strapiPost' ||
        httpMethod === 'strapiGet' ||
        !response?.responseType
      ) {
        response = { responseType: 'ok', payload: response };
      }

      const initialResponse = state?.apiCallWizardResponse || {};
      const initialPendingStatuses = state?.isApiCallWizardPending
        ? state?.isApiCallWizardPending
        : {};
      const initialErrorStatuses = state?.isApiCallWizardError
        ? state?.isApiCallWizardError
        : {};
      const initialSuccessStatuses = state?.isApiCallWizardSuccess
        ? state?.isApiCallWizardSuccess
        : {};

      if (savedKey === 'nextAutoPayDate') {
        const nextPaymentDate = dateToReadableString(stringToDate(response?.payload));
        const nextAutoPaymentDate = dateToString(stringToDate(response?.payload));
        const isAutoPayInCurrentMonth = nextAutoPaymentDate
          ? isCurrentMonth(nextAutoPaymentDate)
          : false;

        allInitialWizardData = {
          ...allInitialWizardData,
          nextPaymentDate,
          isAutoPayInCurrentMonth,
          autoPayInCurrentMonthText: isAutoPayInCurrentMonth && ' this month',
        };
      }
      if (savedKey === 'customPolicies') {
        const payloadArray = response?.payload?.policies;

        // const sourceAccount = allInitialWizardData?.sourceAccount;
        // const destinationAccount = allInitialWizardData?.destinationAccount;
        if (Array.isArray(payloadArray)) {
          const newPolicies = createCustomPolicies(payloadArray, lobTypes);
          response.payload.policies = newPolicies;
        }
      }
      if (savedKey === 'insurance') {
        const payloadArray = response?.payload?.result?.data;

        // const sourceAccount = allInitialWizardData?.sourceAccount;
        // const destinationAccount = allInitialWizardData?.destinationAccount;
        if (Array.isArray(payloadArray)) {
          const newPolicies = createPolicies(payloadArray, lobTypes);
          response.payload.policies = newPolicies;
        }
      }
      if (savedKey === 'policiesByLob') {
        const payloadArray = response?.payload?.policies;

        // const sourceAccount = allInitialWizardData?.sourceAccount;
        // const destinationAccount = allInitialWizardData?.destinationAccount;
        if (Array.isArray(payloadArray)) {
          const newPolicies = createPolicies(payloadArray, lobTypes);
          response.payload.policies = newPolicies;
        }
      }

      if (savedKey === 'accountCalculations') {
        const accountCalculation = response?.payload;
        const initialAccounts = state?.allInitialWizardData?.accounts || []; // oldStoreData.accounts ||
        // // console.log('initialAccounts: ', initialAccounts);

        const formatAccounts = updateHoldingsWithCalculations(
          initialAccounts,
          initialAccounts,
          accountCalculation,
          params,
        );
        const accounts = formatAccountsPayload(formatAccounts, true);

        initialResponse.accounts = { ...initialResponse?.accounts, payload: accounts };
        allInitialWizardData.accounts = accounts;
        // // console.log('initialResponse.accounts: ', initialResponse.accounts);
      }

      if (savedKey === 'verificationQuoteEmailConfirm') {
        const boolStatus = response?.responseType === statusOk;
        if (boolStatus) {
          allInitialWizardData.isEmailVerified = true;
          allInitialWizardData.verify_email = allInitialWizardData?.pre_email;
          if (verifiedEmailField)
            allInitialWizardData[verifiedEmailField] = allInitialWizardData?.pre_email;
        }
      }

      if (savedKey === 'verifiedEmailSaved') {
        delete allInitialWizardData.antiForgeryToken;
      }

      // draft home claims pre load
      if (savedKey === 'draftClaim') {
        if (response?.payload?.claim) {
          const wizard = state?.wizard || {};
          allInitialWizardData = {
            ...allInitialWizardData,
            ...createPartialClaim(wizard, response?.payload?.claim || {}),
          };
          // console.log('allInitialWizardData draft claim: ', allInitialWizardData);
        }
      }
      if (savedKey === 'precertDetails') {
        if (response?.payload?._id) {
          const wizard = state?.wizard || {};
          allInitialWizardData = {
            ...allInitialWizardData,
            ...createPrecerts(wizard, response?.payload || {}),
          };
          // console.log('allInitialWizardData draft claim: ', allInitialWizardData);
        }
      }

      if (savedKey === 'cartPayDetails') {
        if (response?.payload?.guestSessionId) {
          const wizard = state?.wizard || {};
          allInitialWizardData = {
            ...allInitialWizardData,
            ...createPartialPayanything(wizard, response?.payload || {}),
          };
        }
      }

      if (savedKey === 'draftApplication') {
        if (response?.payload?.application) {
          const wizard = state?.wizard || {};
          allInitialWizardData = {
            ...allInitialWizardData,
            ...createApplication(wizard, response?.payload?.application || {}),
          };
          // console.log('allInitialWizardData draft claim: ', allInitialWizardData);
        }
      }

      if (filterSettings && filterSettings.length > 0) {
        filterSettings?.map(filterSetting => {
          const payloadArray = getRef(response, filterSetting?.referenceKey) || [];
          const newPayload = filterResponsePayload(payloadArray, filterSetting);
          setRef(response, filterSetting?.referenceKey, newPayload);
        });
      }

      const newResponse = { ...initialResponse, [savedKey]: response };
      const newPendingStatuses = { ...initialPendingStatuses, [savedKey]: false };
      const newSuccessStatuses = { ...initialSuccessStatuses, [savedKey]: true };
      const newErrorStatuses = { ...initialErrorStatuses, [savedKey]: false };

      const allWizardData = {
        ...state.allWizardData,
        [userId]: allInitialWizardData,
      };

      const newState = {
        ...state,
        isApiCallWizardPending: newPendingStatuses,
        isApiCallWizardError: newErrorStatuses,
        isApiCallWizardSuccess: newSuccessStatuses,
        apiCallWizardResponse: newResponse, // newProducts,
        allWizardData,
        allInitialWizardData,
      };
      // console.log('allInitialWizardData111: ', newState?.allInitialWizardData);
      if (appendKeys && appendKeys.length > 0) {
        appendKeys.forEach(key => {
          // console.log('key: ', key);
          // console.log('newState: ', newState);
          const tempValue =
            transformReducerValue(newState, key?.referenceKey) ||
            sourceTextFromData(newState, key?.staticValue);
          if (tempValue) {
            setRef(newState.allInitialWizardData, key?.key, tempValue);
          }
        });
        // console.log('allInitialWizardData22: ', newState?.allInitialWizardData);
      }

      return newState;
    }
    case API_CALL_WIZARD_API_ERROR: {
      let error = action?.payload;

      const httpMethod = action?.apiCall?.httpMethod;

      if (
        httpMethod === 'strapiPost' ||
        httpMethod === 'strapiGet' ||
        !error.responseType
      ) {
        error = { responseType: 'error', payload: error };
      }

      // // console.log('error: ', error);
      const savedKey = action?.savedKey;
      // // console.log('savedKey: ', savedKey);
      const initialPendingStatuses = state?.isApiCallWizardPending
        ? state?.isApiCallWizardPending
        : {};
      const initialErrorStatuses = state?.isApiCallWizardError
        ? state?.isApiCallWizardError
        : {};
      const initialSuccessStatuses = state?.isApiCallWizardSuccess
        ? state?.isApiCallWizardSuccess
        : {};

      const initialError = state?.apiCallWizardError ? state?.apiCallWizardError : {};
      const newError = { ...initialError, [savedKey]: error };
      const initialResponse = state?.apiCallWizardResponse;
      const newResponse = { ...initialResponse, [savedKey]: error };
      // // console.log('newResponse: ', newResponse);
      const newPendingStatuses = { ...initialPendingStatuses, [savedKey]: false };
      // // console.log('newPendingStatuses: ', newPendingStatuses);
      const newSuccessStatuses = { ...initialSuccessStatuses, [savedKey]: false };
      // // console.log('newSuccessStatuses: ', newSuccessStatuses);
      const newErrorStatuses = { ...initialErrorStatuses, [savedKey]: true };
      // console.log('newErrorStatuses: ', newErrorStatuses);

      return {
        ...state,
        isApiCallWizardPending: newPendingStatuses,
        isApiCallWizardError: newErrorStatuses,
        isApiCallWizardSuccess: newSuccessStatuses,
        apiCallWizardError: newError,
        apiCallWizardResponse: newResponse,
      };
    }

    case DUTCH_API_CALL_WIZARD_API_SUCCESS: {
      const userId = state?.allInitialWizardData?.auth?.userId || 'none';
      const allInitialWizardData = {
        ...state?.allInitialWizardData,
        [action.savedKey]: action.payload,
      };
      const allWizardData = {
        ...state.allWizardData,
        [userId]: allInitialWizardData,
      };

      const newState = {
        ...state,
        allWizardData,
        allInitialWizardData,
      };
      return newState;
    }

    case DUTCH_API_CALL_WIZARD_API_ERROR: {
      const userId = state?.allInitialWizardData?.auth?.userId || 'none';
      const allInitialWizardData = {
        ...state?.allInitialWizardData,
        [action.savedKey]: action.payload,
      };
      const allWizardData = {
        ...state.allWizardData,
        [userId]: allInitialWizardData,
      };

      const newState = {
        ...state,
        allWizardData,
        allInitialWizardData,
      };
      return newState;
    }

    case ADD_LOCAL_WIZARD_SUCCESS: {
      const wizard = action?.payload || null;

      // const wizardData = { ...response };
      // const allWizardData = { ...state.allWizardData, ...response };

      return {
        ...state,
        isApiCallWizardPending: false,
        isApiCallWizardError: false,
        isApiCallWizardSuccess: false,
        isSearchCallWizardPending: false,
        isSearchCallWizardError: false,
        isSearchCallWizardSuccess: false,
        isGettingWizardSuccess: true,
        // apiCallWizardResponse: initialState.apiCallWizardResponse,
        wizard,
        // resettedWizardData: false,
      };
    }

    case ADD_LOCAL_MODAL_WIZARD_SUCCESS: {
      const modalWizard = action?.payload || null;
      // const wizardData = { ...response };
      // const allWizardData = { ...state.allWizardData, ...response };

      return {
        ...state,
        // isApiCallWizardPending: false,
        // isApiCallWizardError: false,
        // isApiCallWizardSuccess: false,
        // isSearchCallWizardPending: false,
        // isSearchCallWizardError: false,
        // isSearchCallWizardSuccess: false,
        modalWizard,
        // resettedWizardData: false,
      };
    }

    case ADD_INITIAL_WIZARD_DATA_SUCCESS: {
      const response = action.payload || {};
      // console.log('response: ', response);
      const userId = state?.allInitialWizardData?.auth?.userId || 'none';
      const dataSettings = action?.dataSettings || null;
      const { focusKey } = action.payload;
      const initialWizardData = { ...response };
      // console.log('initialWizardData: ', initialWizardData);
      const nowTime = new Date().getTime();
      const initialAllWizardData = { ...state.allInitialWizardData };
      let finalAllInitialWizardData = {};

      if (dataSettings?.isSharedObject) {
        finalAllInitialWizardData = mergeDeep(initialAllWizardData, initialWizardData, {
          [persistKey]: nowTime,
        });
      } else {
        finalAllInitialWizardData = {
          ...initialAllWizardData,
          ...initialWizardData,
          [persistKey]: nowTime,
        };
      }

      if (dataSettings?.appendKeys) {
        for (let i = 0; i < dataSettings?.appendKeys.length; i++) {
          const appendKey = dataSettings?.appendKeys[i];
          // console.log('appendKey: ', appendKey);
          const { show: subShow } = checkComponent(
            appendKey,
            finalAllInitialWizardData,
            null,
            null,
          );
          // console.log('subShow: ', subShow);

          const reducerKeyData = getRef(
            finalAllInitialWizardData,
            appendKey.referenceKey,
          );
          const staticValueData = sourceTextFromData(
            finalAllInitialWizardData,
            appendKey.staticValue,
          );
          const newReducerKeyValue = transformConditionalValue(
            reducerKeyData,
            appendKey.fieldType,
          );
          const newStaticValue = transformConditionalValue(
            staticValueData,
            appendKey.fieldType,
          );
          if (subShow) {
            setRef(
              finalAllInitialWizardData,
              appendKey?.key,
              newReducerKeyValue || newStaticValue,
            );
          } else {
            setRef(finalAllInitialWizardData, appendKey?.key, newStaticValue);
          }
        }
      }

      const allWizardData = {
        ...state.allWizardData,
        [userId]: finalAllInitialWizardData,
      };

      if (focusKey === 'sourceSecurity') {
        finalAllInitialWizardData.selectedSourceSecurity = finalAllInitialWizardData?.sourceAccountPayload?.holdings?.find(
          x => x?.secCode === action?.payload?.sourceSecurity,
        );
      }
      // console.log('allInitialWizardData: ', allInitialWizardData);
      return {
        ...state,
        initialWizardData,
        allInitialWizardData: finalAllInitialWizardData,
        allWizardData,
        focusKey,
        isGettingWizardPending: false,
        isGettingWizardError: false,
        isGettingWizardSuccess: false,
        resettedWizardData: false,
        previousWizardData: state.allInitialWizardData,

        // isLoadingWizard: true,
      };
    }

    case EMPTY_INITIAL_WIZARD_DATA_SUCCESS: {
      const response = action.payload || {};
      const userId = state?.allInitialWizardData?.auth?.userId || 'none';
      const { focusKey } = action.payload;
      const initialWizardData = { ...response };
      const nowTime = new Date().getTime();
      const allInitialWizardData = {
        ...response,
        [persistKey]: nowTime,
      };

      const allWizardData = {
        ...state.allWizardData,
        [userId]: allInitialWizardData,
      };

      return {
        ...state,
        initialWizardData,
        allInitialWizardData,
        allWizardData,
        focusKey,
        isGettingWizardPending: false,
        isGettingWizardError: false,
        isGettingWizardSuccess: false,
        resettedWizardData: false,

        // isLoadingWizard: true,
      };
    }

    /* case LOADING_WIZARD_SUCCESS: {
      const response = action.payload || false;

      return {
        ...state,
        isLoadingWizard: response,
      };
    } */

    case ADD_LAST_ENTRY_POINT_SUCCESS: {
      const lastEntryPoint = action?.payload;
      return {
        ...state,
        lastEntryPoint,
      };
    }

    case RESET_WIZARD_DATA_SUCCESS: {
      const userId = state?.allInitialWizardData?.auth?.userId || 'none';
      const clearResponse = action?.clearResponse || false;
      const modalWizardSlug = clearResponse ? null : state.modalWizardSlug;
      const wizardData = {};
      const newInitialData = { ...state.allInitialWizardData, ...action?.payload };
      const nextBackButtonLink = action?.params?.nextBackButtonLink || null;
      // const onLoadApiCalls = action?.onLoadApiCalls || [];

      // console.log('state.allInitialWizardData: ', state.allInitialWizardData);

      const allWizardData = {
        ...state.allWizardData,
        [userId]: newInitialData,
      };

      const newApiResponse = clearResponse
        ? initialState.apiCallWizardResponse
        : state.apiCallWizardResponse;

      return {
        ...state,
        apiCallWizardResponse: newApiResponse, // s initialState.apiCallWizardResponse,
        lastAllInitialWizardData: state.allInitialWizardData,
        isApiCallWizardPending: initialState.isApiCallWizardPending,
        isApiCallWizardError: initialState.isApiCallWizardError,
        isApiCallWizardSuccess: initialState.isApiCallWizardSuccess,
        apiCallWizardError: initialState.apiCallWizardError,
        allInitialWizardData: newInitialData,
        allWizardData,
        resettedWizardData: true,
        nextBackButtonLink,
        lastEntryPoint: state.lastEntryPoint,
        modalWizardSlug,
      };
    }

    case SELECT_CLIENTS_SUCCESS: {
      const userId = state?.allInitialWizardData?.auth?.userId || 'none';
      const newInitialData = { ...initialState.allInitialWizardData };

      const allWizardData = {
        ...state.allWizardData,
        [userId]: newInitialData,
      };
      return {
        ...state,
        // apiCallWizardResponse: initialState.apiCallWizardResponse,
        isApiCallWizardPending: initialState.isApiCallWizardPending,
        isApiCallWizardError: initialState.isApiCallWizardError,
        isApiCallWizardSuccess: initialState.isApiCallWizardSuccess,
        apiCallWizardError: initialState.apiCallWizardError,
        allInitialWizardData: newInitialData,
        allWizardData,
        resettedWizardData: true,
        lastEntryPoint: state.lastEntryPoint,
      };
    }

    case RESET_WIZARD_SUCCESS: {
      return {
        ...initialState,
        // apiCallWizardError: state.apiCallWizardError,
        apiCallWizardResponse: state.apiCallWizardResponse,
        initialWizardData: state.initialWizardData,
        allInitialWizardData: state.allInitialWizardData,
        wizard: state.wizard,
        resettedWizardData: state.resettedWizardData,
        nextBackButtonLink: state.nextBackButtonLink,
        sentEmail: state.sentEmail,
        lastEntryPoint: state.lastEntryPoint,
      };
    }

    case RESET_WIZARD_FIELDS_ONCHANGE: {
      const { resetFields } = action.payload;
      const { allInitialWizardData } = state;
      const updatedAllInitialWizardData = { ...allInitialWizardData };
      if (Array.isArray(resetFields) && resetFields.length > 0) {
        resetFields?.forEach(obj => {
          updatedAllInitialWizardData[obj.name] = obj.staticValue;
        });
      }
      return {
        ...state,
        allInitialWizardData: updatedAllInitialWizardData,
        resetOnChange: true,
      };
    }

    case GET_WIZARD_API_REQUEST:
      return {
        ...state,
        isApiCallWizardPending: false,
        isApiCallWizardError: false,
        isApiCallWizardSuccess: false,
        isSearchCallWizardPending: false,
        isSearchCallWizardError: false,
        isSearchCallWizardSuccess: false,
        isGettingWizardPending: true,
        isGettingWizardError: false,
        isGettingWizardSuccess: false,
      };
    case GET_WIZARD_API_SUCCESS: {
      const wizard =
        !!action?.payload && action.payload.length > 0 ? action.payload[0] : null;
      // const quotes = response?.payload || [];
      const response = action?.payload;
      response.payload = null;
      return {
        ...state,
        isGettingWizardPending: false,
        isGettingWizardError: false,
        isGettingWizardSuccess: true,
        getWizardResponse: response, // action.payload, // newProducts,
        // apiCallWizardResponse: initialState.apiCallWizardResponse,
        wizard,
        // resettedWizardData: false,
      };
    }
    case GET_WIZARD_API_ERROR:
      return {
        ...state,
        isGettingWizardPending: false,
        isGettingWizardError: true,
        isGettingWizardSuccess: false,
        // getWizardError: action.payload,
      };

    case GET_MODAL_WIZARD_API_REQUEST:
      return {
        ...state,
        // isApiCallWizardPending: false,
        // isApiCallWizardError: false,
        // isApiCallWizardSuccess: false,
        // isSearchCallWizardPending: false,
        // isSearchCallWizardError: false,
        // isSearchCallWizardSuccess: false,
        isGettingModalWizardPending: true,
        isGettingModalWizardError: false,
        isGettingModalWizardSuccess: false,
      };
    case GET_MODAL_WIZARD_API_SUCCESS: {
      const modalWizard =
        !!action?.payload && action.payload.length > 0 ? action.payload[0] : null;
      // const quotes = response?.payload || [];
      const response = action?.payload;
      response.payload = null;
      return {
        ...state,
        isGettingModalWizardPending: false,
        isGettingModalWizardError: false,
        isGettingModalWizardSuccess: true,
        getModalWizardResponse: response, // action.payload, // newProducts,
        modalWizard,
        // resettedWizardData: false,
      };
    }
    case GET_MODAL_WIZARD_API_ERROR:
      return {
        ...state,
        isGettingModalWizardPending: false,
        isGettingModalWizardError: true,
        isGettingModalWizardSuccess: false,
        // getWizardError: action.payload,
      };

    case SEARCH_CALL_WIZARD_API_REQUEST: {
      const savedKey = action?.savedKey;

      const initialPendingStatuses = state?.isSearchCallWizardPending
        ? state?.isSearchCallWizardPending
        : {};
      const initialErrorStatuses = state?.isSearchCallWizardError
        ? state?.isSearchCallWizardError
        : {};
      const initialSuccessStatuses = state?.isSearchCallWizardSuccess
        ? state?.isSearchCallWizardSuccess
        : {};

      const newPendingStatuses = {
        ...initialPendingStatuses,
        [savedKey]: true,
      };
      const newSuccessStatuses = { ...initialErrorStatuses, [savedKey]: false };
      const newErrorStatuses = { ...initialSuccessStatuses, [savedKey]: false };
      return {
        ...state,
        isSearchCallWizardPending: newPendingStatuses,
        isSearchCallWizardError: newErrorStatuses,
        isSearchCallWizardSuccess: newSuccessStatuses,
      };
    }
    case SEARCH_CALL_WIZARD_API_SUCCESS: {
      const response = action?.payload;
      const savedKey = action?.savedKey;

      const initialResponse = state?.searchCallWizardResponse
        ? state?.searchCallWizardResponse
        : {};
      const initialPendingStatuses = state?.isSearchCallWizardPending
        ? state?.isSearchCallWizardPending
        : {};
      const initialErrorStatuses = state?.isSearchCallWizardError
        ? state?.isSearchCallWizardError
        : {};
      const initialSuccessStatuses = state?.isSearchCallWizardSuccess
        ? state?.isSearchCallWizardSuccess
        : {};

      const initialApiCallWizardResponse = state?.apiCallWizardResponse;
      const apiResponse = { ...initialApiCallWizardResponse, [savedKey]: response };

      const newResponse = { ...initialResponse, [savedKey]: response };

      const newPendingStatuses = { ...initialPendingStatuses, [savedKey]: false };
      const newSuccessStatuses = { ...initialErrorStatuses, [savedKey]: true };
      const newErrorStatuses = { ...initialSuccessStatuses, [savedKey]: false };

      return {
        ...state,
        isSearchCallWizardPending: newPendingStatuses,
        isSearchCallWizardError: newErrorStatuses,
        isSearchCallWizardSuccess: newSuccessStatuses,
        searchCallWizardResponse: newResponse, // newProducts,
        apiCallWizardResponse: apiResponse,
      };
    }
    case SEARCH_CALL_WIZARD_API_ERROR: {
      const error = action?.payload;
      const savedKey = action?.savedKey;
      const initialPendingStatuses = state?.isSearchCallWizardPending
        ? state?.isSearchCallWizardPending
        : {};
      const initialErrorStatuses = state?.isSearchCallWizardError
        ? state?.isSearchCallWizardError
        : {};
      const initialSuccessStatuses = state?.isSearchCallWizardSuccess
        ? state?.isSearchCallWizardSuccess
        : {};

      const initialError = state?.searchCallWizardError
        ? state?.searchCallWizardError
        : {};
      const newError = { ...initialError, [savedKey]: error };
      const newPendingStatuses = { ...initialPendingStatuses, [savedKey]: false };
      const newSuccessStatuses = { ...initialErrorStatuses, [savedKey]: false };
      const newErrorStatuses = { ...initialSuccessStatuses, [savedKey]: true };

      return {
        ...state,
        isSearchCallWizardPending: newPendingStatuses,
        isSearchCallWizardError: newErrorStatuses,
        isSearchCallWizardSuccess: newSuccessStatuses,
        // searchCallWizardError: newError,
      };
    }

    case RESET_WIZARD_FLAG_SUCCESS:
      return {
        ...state,
        resettedWizardData: action.payload,
      };

    case SET_API_CALL_PENDING: {
      return {
        ...state,
        isApiCallWizardPending: action?.payload,
        isApiCallWizardSuccess: false,
        isApiCallWizardError: false,
      };
    }

    case SET_MODAL_API_CALL_PENDING: {
      return {
        ...state,
        isModalApiCallWizardPending: action.payload,
      };
    }
    case ADD_MODAL_WIZARD_SLUG_SUCCESS: {
      return {
        ...state,
        modalWizardSlug: action.payload,
      };
    }

    case RESET_MODAL_WIZARD_SLUG_SUCCESS: {
      return {
        ...state,
        modalWizardSlug: null,
      };
    }

    case SET_CHECKBOX_STATE: {
      const { flag } = action.payload;
      return {
        ...state,
        estimateQuoteCheckboxState: flag,
      };
    }

    default:
      return state;
  }
}
export default wizardReducer;

/*
      if (
        focusKey === 'sourceSecurity' ||
        focusKey === 'sourceAccount' ||
        focusKey === 'destinationAccount' ||
        focusKey === 'destinationSecurity'
      ) {
        const accounts = state?.apiCallWizardResponse?.accounts?.payload;
        const sourceAccount = allInitialWizardData?.sourceAccount;
        if (sourceAccount && accounts && accounts.length > 0) {
          const sourceAccountPayload = accounts?.find(x2 => x2.id === sourceAccount);
          allInitialWizardData.sourceAccountPayload = sourceAccountPayload;
          const sourceSecurity = allInitialWizardData?.sourceSecurity;
          if (sourceSecurity && sourceAccountPayload) {
            allInitialWizardData.sourceSecurityPayload = sourceAccountPayload?.holdings?.find(
              x2 => x2.secCode === sourceSecurity,
            );
          }
        }

        const destinationAccount = allInitialWizardData?.destinationAccount;
        if (destinationAccount && accounts && accounts.length > 0) {
          const destinationAccountPayload = accounts?.find(
            x2 => x2.id === destinationAccount,
          );
          allInitialWizardData.destinationAccountPayload = destinationAccountPayload;
          const destinationSecurity = allInitialWizardData?.destinationSecurity;
          if (destinationSecurity && destinationAccountPayload) {
            allInitialWizardData.destinationSecurityPayload = destinationAccountPayload?.holdings?.find(
              x2 => x2.secCode === destinationSecurity,
            );
          }
        }
      }
      */

/*

    case ADD_WIZARD_DATA_SUCCESS: {
      const response = action.payload || {};
      const wizardData = { ...response };
      const allWizardData = { ...state.allWizardData, ...response };

      return {
        ...state,
        wizardData,
        allWizardData,
      };
    }

  GET_VEHICLES_API_REQUEST,
  GET_VEHICLES_API_SUCCESS,
  GET_VEHICLES_API_ERROR,
  SUBMIT_WIZARD_API_REQUEST,
  SUBMIT_WIZARD_API_SUCCESS,
  SUBMIT_WIZARD_API_ERROR,

    getVehiclesResponse: null,
  isGettingVehiclesPending: false,
  isGettingVehiclesError: false,
  isGettingVehiclesSuccess: false,
  getVehiclesError: null,
  vehicles: [],
  submitWizardResponse: null,
  isSubmittingWizardPending: false,
  isSubmittingWizardError: false,
  isSubmittingWizardSuccess: false,
  submitWizardError: null,

    case GET_VEHICLES_API_REQUEST:
      return {
        ...state,
        isGettingVehiclesPending: true,
        isGettingVehiclesError: false,
        isGettingVehiclesSuccess: false,
      };
    case GET_VEHICLES_API_SUCCESS: {
      const response = action.payload;
      const vehicles = response?.payload?.vehicles || [];
      return {
        ...state,
        isGettingVehiclesPending: false,
        isGettingVehiclesError: false,
        isGettingVehiclesSuccess: true,
        getVehiclesResponse: action.payload, // newProducts,
        vehicles,
      };
    }
    case GET_VEHICLES_API_ERROR:
      return {
        ...state,
        isGettingVehiclesPending: false,
        isGettingVehiclesError: true,
        isGettingVehiclesSuccess: false,
        getVehiclesError: action.payload,
      };

    case SUBMIT_WIZARD_API_REQUEST:
      return {
        ...state,
        isSubmittingWizardPending: true,
        isSubmittingWizardError: false,
        isSubmittingWizardSuccess: false,
      };
    case SUBMIT_WIZARD_API_SUCCESS: {
      const response = action.payload;

      return {
        ...state,
        isSubmittingWizardPending: false,
        isSubmittingWizardError: false,
        isSubmittingWizardSuccess: true,
        submitWizardResponse: response, // newProducts,
      };
    }
    case SUBMIT_WIZARD_API_ERROR:
      return {
        ...state,
        isSubmittingWizardPending: false,
        isSubmittingWizardError: true,
        isSubmittingWizardSuccess: false,
        submitWizardError: action.payload,
      };
      */
