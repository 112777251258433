import { useSelector } from 'react-redux';
import {
  parseBool,
  parseSmoker,
  parseBoolOrString,
} from '../../../utils/functions/boolManipulation';
import {
  sourceTextFromData,
  getRef,
  toTitleCase,
  getTransformPeriod,
  getEnv,
  unFormatAlpha2,
  formatAlpha2,
} from '../../../utils/functions';
// import { getRef } from './objectManipulation';
import { setRef, deepCloneRemoval } from '../../../utils/functions/objectManipulation'; // setObjByString,
import {
  getAgeFromDate,
  getMonths,
  dateToString,
  getYearMonthString,
  getYearFromDate,
} from '../../../utils/functions/dateManipulation';
import { transformReducerValue, checkComponent } from './misc';
import { agentType } from '../../../utils/variables';
import { addWizard } from '../../../actions/wizards';
import { searchCallWizardApi } from '../../../apis/wizards';
import { createPayload as createClientPayload } from '../../../reducers/clients';
import {
  createCustomPolicies,
  createPolicies,
} from '../../../reducers/portfolio/insurance';

export const fetchData = (
  apiCall,
  callAction,
  dispatch,
  state = {},
  additionalParams = {},
  disablePending = false,
  isLocal = false,
) => {
  if (!apiCall) return Promise.resolve(null);
  // console.log('apiCall: ', apiCall);
  // if (apiCall.emailVerified && !state.wizards.allInitialWizardData.email) {
  //  return Promise.resolve(null);
  // }
  const lobTypes = state?.locations?.lobCategories;
  // // console.log('apiCall: ', apiCall);
  let files = [];
  const newParams = apiCall?.params?.reduce((acc, curObj) => {
    const key = curObj?.key;
    const reducerKey = curObj?.reducerKey;
    const isBoolean = curObj?.isBoolean;
    const isValidBoolean = curObj?.isValidBoolean;
    const isBooleanOrString = curObj?.isBooleanOrString;
    const isBooleanInteger = curObj?.isBooleanInteger;
    const isNumeric = curObj?.isNumeric;
    const isString = curObj?.isString;
    const isAge = curObj?.isAge;
    const isMonths = curObj?.isMonths;
    const isYearMonthString = curObj?.isYearMonthString;
    const isAgent = curObj?.isAgent;
    const isSmoker = curObj?.isSmoker;
    const addToFiles = curObj?.addToFiles;
    const isFixedToTwo = curObj?.isFixedToTwo;
    let staticValue = curObj?.staticValue;
    const isTitleCase = curObj?.isTitleCase;
    const isCurrency = curObj?.isCurrency;
    const transformPeriod = curObj?.transformPeriod;
    const isYearOnly = curObj?.isYearOnly;
    const isAlwaysArray = curObj?.isAlwaysArray;
    const dontSetIfEmpty = curObj?.dontSetIfEmpty;
    const emptyKey = curObj?.emptyKey;
    const isEnv = curObj?.isEnv;
    const isUnFormatAlpha2 = curObj?.unFormatAlpha2;
    const isFormatAlpha2 = curObj?.formatAlpha2;
    const isBooleanYesNo = curObj?.isBooleanYesNo;
    // let reducerValue = getRef(state, reducerKey);
    // state.motorClientKey = motorClientKey;
    // console.log('state: ', state);

    const { show } = checkComponent(curObj, state, false, false, true);
    if (!show) return acc;

    const isAgentApp =
      state?.auth?.user?.agent?.id && state?.auth?.usertype === agentType;
    let finalStoreValue = transformReducerValue(state, reducerKey);

    staticValue = sourceTextFromData(state, staticValue);

    // console.log('reducerKey: ', reducerKey);
    // console.log('apicall state: ', state);
    // console.log('finalStoreValue: ', finalStoreValue);

    if (isEnv) {
      // console.log('staticValue before: ', { ...staticValue });
      staticValue = staticValue && JSON.parse(staticValue)[getEnv()];
      // console.log('staticValue after: ', staticValue);
      // console.log('finalStoreValue: ', finalStoreValue);
    }
    if (staticValue === 'nowDate') {
      staticValue = dateToString(new Date());
    }
    if (transformPeriod) {
      staticValue = getTransformPeriod(staticValue);
      finalStoreValue = getTransformPeriod(finalStoreValue);
    }
    if (isYearOnly) {
      staticValue = getYearFromDate(staticValue);
      finalStoreValue = getYearFromDate(finalStoreValue);
    }
    if (isMonths) {
      staticValue = getMonths(staticValue);
      finalStoreValue = getMonths(finalStoreValue);
    }
    if (isYearMonthString) {
      staticValue = getYearMonthString(staticValue);
      finalStoreValue = getYearMonthString(finalStoreValue);
    }
    if (isBoolean) {
      staticValue = parseBool(staticValue);
      finalStoreValue = parseBool(finalStoreValue);
    }
    if (isBooleanYesNo) {
      staticValue = staticValue ? parseBoolOrString(staticValue) : undefined;
      finalStoreValue = finalStoreValue ? parseBoolOrString(finalStoreValue) : undefined;
      finalStoreValue = finalStoreValue ? 'Yes' : 'No';
    }
    if (isValidBoolean) {
      staticValue = staticValue ? parseBool(staticValue) : undefined;
      finalStoreValue = finalStoreValue ? parseBool(finalStoreValue) : undefined;
      if (staticValue === undefined && finalStoreValue !== undefined) {
        staticValue = finalStoreValue;
      }
    }
    if (isBooleanOrString) {
      staticValue = staticValue ? parseBoolOrString(staticValue) : undefined;
      finalStoreValue = finalStoreValue ? parseBoolOrString(finalStoreValue) : undefined;
      if (staticValue === undefined && finalStoreValue !== undefined) {
        staticValue = finalStoreValue;
      }
    }
    if (isTitleCase) {
      staticValue = toTitleCase(staticValue);
      finalStoreValue = toTitleCase(finalStoreValue);
    }
    if (isString) {
      staticValue = staticValue?.toString();
      finalStoreValue = finalStoreValue?.toString();
    }
    if (isNumeric) {
      staticValue =
        staticValue && !Number.isNaN(staticValue) ? parseFloat(staticValue) : null; // staticValue;
      finalStoreValue =
        finalStoreValue && !Number.isNaN(finalStoreValue)
          ? parseFloat(finalStoreValue)
          : null; // finalStoreValue;
    }
    if (isBooleanInteger) {
      staticValue = parseBool(staticValue) ? 1 : 0;
      finalStoreValue = parseBool(finalStoreValue) ? 1 : 0;
    }
    if (isAge) {
      staticValue = getAgeFromDate(staticValue);
      finalStoreValue = getAgeFromDate(finalStoreValue);
    }

    if (isAgent) {
      staticValue = isAgentApp ? staticValue : undefined;
      finalStoreValue = isAgentApp ? finalStoreValue : undefined;
    }
    if (isSmoker) {
      staticValue = parseSmoker(staticValue);
      finalStoreValue = parseSmoker(finalStoreValue);
    }

    if (isUnFormatAlpha2) {
      staticValue = unFormatAlpha2(staticValue);
      finalStoreValue = unFormatAlpha2(finalStoreValue);
    }
    if (isFormatAlpha2) {
      staticValue = formatAlpha2(staticValue);
      finalStoreValue = formatAlpha2(finalStoreValue);
    }
    if (isAlwaysArray) {
      if (staticValue) staticValue = [staticValue];
      finalStoreValue = [finalStoreValue];
    }

    if (isFixedToTwo) {
      // console.log('staticValue', staticValue);
      // console.log('finalStoreValue', finalStoreValue);
      // staticValue = parseFloat(staticValue?.toString())?.toFixed(2);
      // finalStoreValue = parseFloat(finalStoreValue?.toString())?.toFixed(2);
      staticValue = staticValue
        ? parseFloat(staticValue?.toString())?.toFixed(2)
        : undefined; // staticValue;
      finalStoreValue = finalStoreValue
        ? parseFloat(finalStoreValue?.toString())?.toFixed(2)
        : undefined;
    }

    if (isCurrency) {
      const dp = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      };
      staticValue = staticValue
        ? `$${parseFloat(staticValue).toLocaleString(undefined, dp)}`
        : undefined;
      finalStoreValue = finalStoreValue
        ? `$${parseFloat(finalStoreValue).toLocaleString(undefined, dp)}`
        : undefined;
    }
    // console.log('finalStoreValue2', finalStoreValue);

    if (addToFiles) {
      // console.log('reducerKey: ', reducerKey);
      // console.log('addToFiles: ', addToFiles);
      // console.log('finalStoreValue: ', finalStoreValue);
      const newFiles =
        finalStoreValue && Array.isArray(finalStoreValue) ? finalStoreValue : [];
      files = [...files, ...newFiles];
    }

    const emptyValue = transformReducerValue(state, emptyKey);
    let initialValue =
      dontSetIfEmpty && !emptyValue ? undefined : finalStoreValue || staticValue;

    const hasExtraParams = curObj?.hasExtraParams;
    if (hasExtraParams && initialValue) {
      const extraParams = curObj?.extraParams;
      initialValue = { ...extraParams, ...initialValue };
    }
    const isArrayParams = curObj?.isArrayParam;
    if (isArrayParams) {
      const arrayStoreValue = transformReducerValue(state, reducerKey);
      const arrayParams = curObj?.arrayParams;
      // console.log('arrayParams: ', arrayParams);
      // console.log('arrayStoreValue: ', arrayStoreValue);
      const finalData = arrayStoreValue?.map(arrayObj => {
        state.arrayObjValue = { ...arrayObj };
        let newObj = {};
        const resultValues = arrayParams?.map(mergeObj => {
          // console.log('mergeObj: ', mergeObj);
          const mergeReducerKeyValue = mergeObj?.reducerKeyValue;
          // console.log('mergeReducerKeyValue: ', mergeReducerKeyValue);

          let mergeReducerStoreValue = transformReducerValue(
            state,
            mergeReducerKeyValue,
            true,
          );
          // console.log('mergeReducerStoreValue2: ', mergeReducerStoreValue);
          let mergedStaticValue = sourceTextFromData(state, mergeObj?.staticValue);
          // console.log('mergedStaticValue: ', mergedStaticValue);

          if (mergeObj?.hasExtraParams && mergeReducerStoreValue) {
            const extraParams = mergeObj?.extraParams;
            mergeReducerStoreValue = { ...extraParams, ...mergeReducerStoreValue };
          }

          const mergeEmptyValue = transformReducerValue(state, mergeObj?.emptyKey);

          if (mergeObj?.isValidBoolean) {
            mergedStaticValue = mergedStaticValue
              ? parseBool(mergedStaticValue)
              : undefined;
            mergeReducerStoreValue = mergeReducerStoreValue
              ? parseBool(mergeReducerStoreValue)
              : undefined;
            if (mergedStaticValue === undefined && mergeReducerStoreValue !== undefined) {
              mergedStaticValue = mergeReducerStoreValue;
            }
          }

          const mergeInitialValue =
            mergeObj?.dontSetIfEmpty && !mergeEmptyValue
              ? undefined
              : mergeReducerStoreValue || mergedStaticValue;

          const isStringDotNotation = mergeObj?.isStringDotNotation;
          if (
            isStringDotNotation &&
            mergeInitialValue !== undefined &&
            mergeInitialValue !== null
          ) {
            // console.log('IsStringDotNotation: ', isStringDotNotation);
            setRef(newObj, mergeObj?.key, mergeInitialValue);
            // console.log('setObjectValue: ', acc);
            // console.log('newObj: ', newObj);
            return newObj;
          }

          // console.log('mergeReducerStoreValue: ', mergeReducerStoreValue);
          if (mergeInitialValue === undefined || mergeInitialValue === null) {
            return null;
          }
          newObj = {
            ...newObj,
            [mergeObj?.key]: mergeInitialValue,
          };

          // console.log('newObj: ', newObj);
          return newObj;
        });
        return newObj;
      });
      finalData?.filter(x => {
        return !!x;
      });
      initialValue = finalData && finalData?.length > 0 ? finalData : undefined;
    }

    const isStringDotNotation = curObj?.isStringDotNotation;
    if (isStringDotNotation && initialValue !== undefined && initialValue !== null) {
      // console.log('IsStringDotNotation: ', isStringDotNotation);
      setRef(acc, key, initialValue);
      // console.log('setObjectValue: ', acc);
      return acc;
    }
    if (initialValue !== undefined && initialValue !== null) {
      const finalObject = {
        [key]: initialValue,
      };
      return Object.assign(acc, finalObject);
    }
    return acc;

    // if (initialValue === undefined || initialValue === null) {
    //   b[key] = '-';
    // }
  }, {});
  // // console.log('newParams: ', newParams);
  // console.log('params are:', apiCall?.params);
  const savedKey = apiCall?.savedKey;
  let mergedParams = { ...newParams, ...additionalParams };
  if (files && files?.length > 0) {
    mergedParams.files = files;
  }
  // // console.log('mergedParams: ', mergedParams);
  // // console.log('callAction: ', callAction);
  const updatedUrl = sourceTextFromData(state, apiCall?.url);

  mergedParams = deepCloneRemoval(mergedParams);

  if (apiCall?.httpMethod === 'localPost') {
    // console.log('apiCall: ', apiCall);
    let data = {};
    if (apiCall?.storeMethod === 'replace') {
      data = { [savedKey]: mergedParams };
    } else if (apiCall?.storeMethod === 'merge') {
      if (state?.wizards?.allInitialWizardData[savedKey] === undefined) {
        data = { [savedKey]: mergedParams };
      } else {
        data = {
          [savedKey]: Object.assign(
            state?.wizards?.allInitialWizardData[savedKey],
            mergedParams,
          ),
        };
      }
    } else if (apiCall?.storeMethod === 'appendArrayItem') {
      if (state?.wizards?.allInitialWizardData[savedKey] === undefined) {
        data = { [savedKey]: [mergedParams] };
      } else {
        data[savedKey] = state?.wizards?.allInitialWizardData[savedKey] || [];
        data[savedKey].push(mergedParams);
      }
    } else if (apiCall?.storeMethod === 'replaceArrayItem') {
      if (state?.wizards?.allInitialWizardData[savedKey] === undefined) {
        data = { [savedKey]: [mergedParams] };
      } else {
        const temp = state?.wizards?.allInitialWizardData[savedKey] || [];
        let res = -1;
        const replaceItem = apiCall?.params.find(ele => {
          if (ele.key === apiCall?.replaceItemName) {
            return ele;
          }
        });
        const replaceItemIndex = getRef(state, replaceItem?.reducerKey);
        temp.map((obj, index) => {
          if (obj.index === parseInt(replaceItemIndex, 10)) {
            res = index;
          }
        });
        if (res != -1) temp[res] = mergedParams;
        else temp.push(mergedParams);
        data = { [savedKey]: temp };
      }
    } else if (apiCall?.storeMethod === 'override') {
      if (state?.wizards?.allInitialWizardData[savedKey] === undefined) {
        data = mergedParams;
      } else {
        const temp = state?.wizards?.allInitialWizardData[savedKey] || {};
        Object.keys(mergedParams).map(key => {
          delete temp[key];
        });
        data = { [savedKey]: temp };
        Object.keys(mergedParams).map(item => {
          data = Object.assign(data, {
            item: state?.wizards?.allInitialWizardData[item],
          });
        });
      }
    }
    if (isLocal) {
      return Promise.resolve(data);
    }
    data = deepCloneRemoval(data);
    return dispatch(addWizard.addInitialWizardDataAction(data));
  }

  if (isLocal) {
    return searchCallWizardApi
      .searchCallWizard(state, updatedUrl, mergedParams, apiCall?.httpMethod)
      .then(data => {
        // console.log('data: ', data);
        if (savedKey === 'clients') {
          const clients = data?.payload?.[0]?.result?.data;

          const finalClients = createClientPayload(clients);
          data.payload = finalClients;
        }

        if (savedKey === 'customPolicies') {
          const payloadArray = data?.payload?.policies;

          // const sourceAccount = allInitialWizardData?.sourceAccount;
          // const destinationAccount = allInitialWizardData?.destinationAccount;
          if (Array.isArray(payloadArray)) {
            const newPolicies = createCustomPolicies(payloadArray, lobTypes);
            data.payload.policies = newPolicies;
          }
        }
        if (savedKey === 'insurance') {
          const payloadArray = data?.payload?.result?.data;

          // const sourceAccount = allInitialWizardData?.sourceAccount;
          // const destinationAccount = allInitialWizardData?.destinationAccount;
          if (Array.isArray(payloadArray)) {
            const newPolicies = createPolicies(payloadArray, lobTypes);
            data.payload.policies = newPolicies;
          }
        }
        return Promise.resolve(data);
      });
  }
  return dispatch(
    callAction(updatedUrl, mergedParams, apiCall, savedKey, disablePending),
  );
};

export const getAllData = (
  apiCalls,
  callAction,
  dispatch,
  state,
  params,
  extraSettings,
  action,
) => {
  if (!apiCalls) return Promise.resolve(null);
  // console.log('apiCalls main: ', apiCalls);

  if (action) {
    const tempObj = {};

    const filterPreAppendKeys = action?.preAppendKeys?.filter((call, index) => {
      const defaultHide = true;
      call.conditionalAction = 'show';
      const { show } = checkComponent(
        call,
        state,
        extraSettings?.isAuthenticated,
        extraSettings?.isCustomer,
        defaultHide,
      );
      // console.log('api show: ', show);
      return show;
    });

    filterPreAppendKeys?.map(key => {
      tempObj[key?.key] =
        transformReducerValue(state, key?.referenceKey) ||
        sourceTextFromData(state, key?.staticValue);
      return null;
    });

    state.wizards.allInitialWizardData = {
      ...state.wizards.allInitialWizardData,
      ...tempObj,
    };
  }
  // console.log('pre apprend state: ', state);
  // console.log('apiCalls: ', apiCalls);
  const filteredCalls = apiCalls?.filter((call, index) => {
    const defaultHide = true;
    call.conditionalAction = 'show';
    const { show } = checkComponent(
      call,
      state,
      extraSettings?.isAuthenticated,
      extraSettings?.isCustomer,
      defaultHide,
    );
    // console.log('api show: ', show);
    return show;
  });
  // console.log('filteredCalls: ', filteredCalls);
  // console.log('x: ', x);
  // console.log('action: ', action);

  // console.log('filteredCalls main: ', filteredCalls);
  if (filteredCalls.length === 0) return Promise.resolve(null);
  return Promise.all(
    filteredCalls?.map((x, index) => {
      let apiCall = { ...x };
      apiCall?.modifyApiSettings?.forEach(element => {
        if (element?.disable) return;

        const { show } = checkComponent(element, state, false, false, true);
        if ((show || !element?.isConditionalComponent) && element?.overrideSettings) {
          apiCall = { ...apiCall, ...element.overrideSettings };
        }
      });
      return fetchData(apiCall, callAction, dispatch, state, params);
    }),
  );
};

/*


    const isMergeParams = curObj?.isMergeParams;
    if (isMergeParams) {
      const mergeType = curObj?.mergeType;
      const mergeParams = curObj?.mergeParams;
      if (mergeType === 'combineToArrayOfObjects') {
        const resultValues = mergeParams?.map(mergeObj => {
          const mergeReducerKeyValue = mergeObj?.reducerKeyValue;
          let mergeReducerStoreValue = transformReducerValue(state, mergeReducerKeyValue);
          if (mergeObj?.hasExtraParams) {
            const extraParams = mergeObj?.extraParams;
            mergeReducerStoreValue = { ...extraParams, ...mergeReducerKeyValue };
          }
          return mergeReducerStoreValue;
        });
        initialValue = resultValues;
      } else if (mergeType === 'combineToOneFlatObject') {
        let newObj = {};
        const resultValues = mergeParams?.map(mergeObj => {
          const mergeReducerKeyValue = mergeObj?.reducerKeyValue;
          let mergeReducerStoreValue = transformReducerValue(state, mergeReducerKeyValue);
          if (mergeObj?.hasExtraParams) {
            const extraParams = mergeObj?.extraParams;
            mergeReducerStoreValue = { ...extraParams, ...mergeReducerKeyValue };
          }
          newObj = { ...newObj, ...mergeReducerStoreValue };
          return newObj;
        });
        initialValue = newObj;
      } else if (mergeType === 'combineToOneNestedObject') {
        let newObj = {};
        const resultValues = mergeParams?.map(mergeObj => {
          const mergeReducerKeyValue = mergeObj?.reducerKeyValue;
          let mergeReducerStoreValue = transformReducerValue(state, mergeReducerKeyValue);

          if (mergeObj?.hasExtraParams) {
            const extraParams = mergeObj?.extraParams;
            mergeReducerStoreValue = { ...extraParams, ...mergeReducerKeyValue };
          }

          const isStringDotNotation = mergeObj?.isStringDotNotation;
          if (isStringDotNotation) {
            // console.log('IsStringDotNotation: ', isStringDotNotation);
            setRef(newObj, key, mergeReducerStoreValue);
            // console.log('setObjectValue: ', acc);
            return newObj;
          }

          newObj = { ...newObj, [key]: mergeReducerStoreValue };
          return newObj;
        });
        initialValue = newObj;
      }
    }

    */
